<template>
  <onboarding-layout back-route-name="OnboardingPetName">
    <h1 class="h2">
      {{ heading }}
    </h1>

    <p class="color-text">
      Je krijgt tips die passen bij de leeftijd van {{ petName }}. Weet je de
      dag niet exact? Vul dan de eerste dag van de maand in.
    </p>

    <p class="color-text">
      De Kwispelcoach groeit met je huisdier mee tot de leeftijd van 1 jaar. Na
      deze leeftijd ontvang je geen gerichte snacks meer. Het is dan wel altijd
      mogelijk om alle informatie te vinden onder de zoekfunctie.
    </p>

    <form action="" method="POST" @submit.prevent="onSubmit">
      <Errors :errors="errors" />

      <div class="row date-row">
        <div class="col">
          <label for="formDay" class="visually-hidden">Dag</label>
          <select
            class="select-control"
            name="formDay"
            id="formDay"
            required
            v-model="formDay"
          >
            <option v-for="day in 31" :key="day" :value="day">
              {{ day }}
            </option>
          </select>
        </div>

        <div class="col">
          <label for="formMonth" class="visually-hidden">Maand</label>
          <select
            class="select-control"
            name="formMonth"
            id="formMonth"
            required
            v-model="formMonth"
          >
            <option v-for="(month, key) in months" :key="month" :value="key">
              {{ month }}
            </option>
          </select>
        </div>

        <div class="col">
          <label for="formYear" class="visually-hidden">Jaar</label>
          <select
            class="select-control"
            name="formYear"
            id="formYear"
            required
            v-model="formYear"
          >
            <option v-for="year in years" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <Button button-type="submit" classes="btn--full-width date-button mb-2">
        Naar volgende stap
      </Button>
    </form>

    <button class="date-button-skip" type="button" @click="onSkip">
      Ik weet de geboortedatum (nog) niet
    </button>
  </onboarding-layout>
</template>

<script>
import { parseISO, format } from 'date-fns';
import OnboardingLayout from './OnboardingLayout.vue';
import Errors from '../../components/Errors.vue';
import Button from '../../components/Button.vue';
import pad from '../../helpers/pad';
import months from '../../helpers/months';

const years = [];

for (let i = 0; i < 25; i += 1) {
  years.push((new Date()).getFullYear() - i);
}

years.reverse();

const monthsWithKeys = {};
months.forEach((month, index) => { monthsWithKeys[index + 1] = month; });

export default {
  components: {
    Errors,
    OnboardingLayout,
    Button,
  },

  data() {
    let defaultDay = 1;
    let defaultMonth = (new Date().getMonth()) + 1;
    let defaultYear = (new Date()).getFullYear();

    if (this.$store.state.onboarding.petBirthdate) {
      const date = this.$store.state.onboarding.petBirthdate;
      defaultDay = format(date, 'd');
      defaultMonth = format(date, 'M');
      defaultYear = format(date, 'yyyy');
    }

    return {
      errors: [],
      formDay: defaultDay,
      formMonth: defaultMonth,
      formYear: defaultYear,
      months: monthsWithKeys,
      years,
    };
  },

  created() {
    this.$store.commit('resetLayout', {
      showCat: this.petType === 'cat',
      showDog: this.petType === 'dog',
      showCta: false,
      petName: this.petName,
      progress: 50,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: '3-pet-birthdate',
    });
  },

  methods: {
    onSkip() {
      this.$router.push({ name: 'OnboardingName' });
    },

    onSubmit() {
      this.errors = [];

      const parts = [
        this.formYear,
        pad('00', this.formMonth, true),
        pad('00', this.formDay, true),
      ];

      const date = parseISO(parts.join('-'));

      // Make sure the date is valid (in case the user enters something like 30 february)...
      if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
        this.errors.push('Dit is geen geldige geboortedatum');
        return;
      }

      this.$store.commit('setPetBirthdate', date);

      this.$router.push({ name: 'OnboardingName' });
    },
  },

  computed: {
    heading() {
      const { petName } = this.$store.state.onboarding;

      return `Wat is de geboortedatum van ${petName}?`;
    },

    petType() {
      return this.$store.state.onboarding.petType;
    },

    petName() {
      return this.$store.state.onboarding.petName;
    },
  },
};
</script>

<style>
.date-row {
  max-width: 25rem;
  margin: 0 auto 1.25rem;
}

.date-row > * {
  padding-left: .375rem;
  padding-right: .375rem;
}

.date-row > *:first-child,
.date-row > *:last-child {
  width: 28%;
  max-width: 28%;
}

.date-button {
  max-width: 24.375rem;
}

.date-button-skip {
  background-color: transparent;
  border: 0;
  padding: .5rem 1rem;
  font-family: var(--default-font-family);
  color: var(--heading-color);
}
</style>
